import {
  getTokenApi
} from "../../api/login";
import {
  getToken,
  setToken,
  removeToken,
  setUserInfo
} from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    user: {},
  },

  mutations: {
    SET_USER: (state, user) => {
      state.user = user
    },
  },

  actions: {
    // 获取用户信息
    GetInfo({
      commit
    }) {
      return new Promise((resolve, reject) => {
        getTokenApi({}).then(res => {
          setToken(res.token);
          setUserInfo(JSON.stringify(res.userInfo));
          commit('SET_USER', res)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  }
}

export default user
