import statrequest from "./stat-request";
import request from "@/utils/request";
const prefix_sau="/sauapi";
/**
 * 1、发送ajax请求，从后台服务器获取催化剂下拉列表数据
 * id为催化剂关键字，value为催化剂名称  java -jar ./moco-runner-1.3.0-standalone.jar http -p 9090 -c electric.json
 * @returns 数组，数组元素是对象，其中id表示催化剂id，value表示催化剂名称
 */
export async function getCatalystsData() {
  let data = [];
  await statrequest.post(prefix_sau+"/catalyst/").then(res => {
    if (res.flag) {
      data = res.data;
    }
  });
  return data;
}

/**
 * 2、发送ajax请求，从后台服务器获取查询条件框需要的文件列表数据，开始日期获取大于等于，结束日期获取小于等于日期，都加上等于这个条件
 * id为文件关键字，value为文件名称（自动生成的）
 * @param catalystValue 催化剂id
 * @param startValue 开始日期，2022-05-08
 * @param endValue   结束日期，2022-05-08
 * @param typeValue  样品类型
 * @returns 数组，数组元素是对象，其中id表示文件id，value表示文件名称
 */
export async function getFilesData(catalystValue, startValue, endValue,typeValue) {
  let data = [];
  console.log("getFilesData is ",catalystValue, startValue, endValue);
  await statrequest.post(prefix_sau+"/searchfile/",{
    catalystValue: catalystValue,
    startValue: startValue,
    endValue: endValue,
    sampleType:typeValue
  }).then(res => {
    if (res.flag) {
      data = res.data;
    }
  });
  return data;
}

/**
 * 3、数据统计与分析的查询，发送ajax请求，从后台服务器获取图像文件列表数据
 * id为文件关键字，value为文件名称（自动生成的）
 *
 * @param catalystValue
 * @param startValue
 * @param endValue
 * @param fileValue
 * @param resultValue 识别信息
 * @param typeValue  样品类型
 * @returns 数组，数组元素是对象，其中id表示图像文件id，value表示图像文件名称，url表示图片文件的绝对url
 */
export async function getImageFiles(catalystValue, startValue, endValue, fileValue,resultValue,typeValue) {
  let data = [];
  await statrequest.post(prefix_sau+"/stats/search", {
    catalystValue: catalystValue,
    startValue: startValue,
    endValue: endValue,
    fileValue: fileValue,
    resultValue: resultValue,
    sampleType:typeValue
  }).then(res => {
    if (res.flag) {
      data = (res.data || []).map(ele => {
        let value=ele.value;
        if(value){
          let index=value.lastIndexOf(".");
          if(index!=-1){
            value=value.substring(0,index);
          }
        }
        return {
          ...ele,
          name: value,
          opacity:0
        };
      });
    }
  }).catch(function(error){
    alert("获取数据失败！"+error.message);
  });
  return data;
}

/**
 * 4、数据统计与分析的统计分析，发送ajax请求，从后台获取统计数据
 * @param imageFileIds
 * @param typeValue  样品类型
 * @returns {{arrayLength: number[], arrayNumber: number[], arrayFileGroup: [{groupName: string, fileName: string}], arrayLayer: number[]}}
 */
export async function getAnalysisData(imageFileIds,typeValue) {
  let data = null;
  await statrequest.post(prefix_sau+"/stats/display", {
    imageFileIds: imageFileIds,
    sampleType:typeValue
  }).then(res => {
    console.log(res);
    if (res.flag) {
      data = res.data;
    }
  });
  return data;
}

/**
 * 5、报告管理页面的查询，发送ajax请求，从后台获取报告数据列表，第一页20条记录
 * @param catalystValue 催化剂id
 * @param startValue    开始日期，2022-05-08
 * @param endValue      结束日期，2022-05-10
 * @param fileValue     多个文件id，中间用英文逗号分隔
 * @param currentPage   当前页面编号，从1开始
 * @param pageSize      页面的记录数目
 * @returns {Promise<*[]>}
 */
export async function getReportList(catalystValue, startValue, endValue, fileValue, currentPage, pageSize) {
  console.log("getReportList:" + catalystValue + "," + startValue + "," + endValue + "," + fileValue + "," + currentPage + "," + pageSize);
  if (endValue === -1) {
    endValue = undefined;
  }
  if (startValue === -1) {
    startValue = undefined;
  }
  let data = [];
  await statrequest.post(prefix_sau+"/report/search", {
    catalystValue: catalystValue,
    startValue: startValue,
    endValue: endValue,
    fileValue: fileValue,
    currentPage: currentPage,
    pageSize: pageSize
  }).then(res => {
    data = res;
  });
  return data;
}

/**
 * 6、报告管理页面的生成报告单
 * @param reportIds        多个报告id，中间用英文逗号分隔
 * @returns {Promise<null>}
 */
export async function createReport(reportIds) {
  let data = null;
  await statrequest.post(prefix_sau+"/report/create", {
    reportIds: reportIds
  }).then(res => {
    console.log(res);
    data = res;
  });
  return data;
}
/**
 * 7、报告对话框的上传操作  /report/upload
 * @param id        报告id
 * @returns {Promise<null>}
 */
export async function uploadReport(id) {
  let data = null;
  await statrequest.post(prefix_sau+"/report/upload", {
    id: id
  }).then(res => {
    console.log(res);
    data = res;
  });
  return data;
}

/**
 * 8、报告管理页面的查看
 * @param id        报告id
 * @returns {Promise<null>}
 */
export async function getReport(id) {
  let data = null;
  await statrequest.post(prefix_sau+"/report/look", {
    id: id
  }).then(res => {
    console.log(res);
    if (res.flag) {
      data = res.data;
    }
  });
  return data;
}

/**
 * 9、报告管理页面的编辑（保存）
 * @param  report  报告的json对象
 * @returns {Promise<null>}
 */
export async function editReport(report) {
  let data = null;
  await statrequest.post(prefix_sau+"/report/edit", report).then(res => {
    console.log(res);
    data = res;
  });
  return data;
}

/**
 * 10、报告管理页面的删除
 * @param id     报告id
 * @returns {Promise<null>}
 */
export async function deleteReport(id) {
  let data = null;
  await statrequest.post(prefix_sau+"/report/delete", {
    id: id
  }).then(res => {
    console.log(res);
    data = res;
  });
  return data;
}

/**
 * 11、图像数据存储的查询，发送ajax请求，从后台获取图像数据列表，第一页20条记录
 * @param catalystValue 催化剂id
 * @param startValue    开始日期，2022-05-08
 * @param endValue      结束日期，2022-05-10
 * @param fileValue     多个文件id，中间用英文逗号分隔
 * @param currentPage   当前页面编号，从1开始
 * @param pageSize      页面的记录数目
 * @returns {Promise<*[]>}
 */
export async function getImageStorageList(catalystValue, startValue, endValue, fileValue, currentPage, pageSize) {
  console.log("getImageStorageList:" + catalystValue + "," + startValue + "," + endValue + "," + fileValue + "," + currentPage + "," + pageSize);
  let data = {flag: true, data: [], total: 0};
  await statrequest.post(prefix_sau+"/imagedata/search", {
    catalystValue: catalystValue,
    startValue: startValue,
    endValue: endValue,
    fileValue: fileValue,
    currentPage: currentPage,
    pageSize: pageSize
  }).then(res => {
    data = res;
  });
  return data;
}

/**
 * 12、图像数据的查看
 * @param id        图像数据id
 * @returns {Promise<null>}
 */
export async function getImageStorage(id) {
  let data = null;
  await statrequest.post(prefix_sau+"/imagedata/look", {
    id: id
  }).then(res => {
    if (res.flag) {
      if(res.data.arrayImageFile){
        let tmp=res.data.arrayImageFile.map(ele=>{
          let value=ele.value;
          if(value){
            let index=value.lastIndexOf(".");
            if(index!=-1){
              value=value.substring(0,index);
            }
          }
          return {
            ...ele,
            name: value
          };
          });
        res.data.arrayImageFile=tmp;
      }
      data=res.data;
    }
  }).catch(function(error){
    alert("获取数据失败！"+error.message);
  });
  console.log(data);
  return data;
}
/**
 * 等待服务端确认端口
 * 13、图像数据存储的导出  /imagedata/upload
 * id图像数据id
 * 图像数据对话框中的导出按钮操作，返回excel或文本文件，
 * 文件格式自己确定，内容是图片的长度、层数和数量的原始数据。 responseType: 'blob'
 */
export async function exportImageStorage(id) {
  let data = null;
  await statrequest.post(prefix_sau+"/imagedata/upload", {
    id: id
  },{responseType: 'blob'}).then(res => {
    data = res;
  }).catch(function(error){
    alert("获取数据失败！"+error.message);
  });
  return data;
}
/**
 * 14、报告管理页面的获取文件夹内所有图片文件  该接口废弃
 * @param id        报告id
 * @returns {Promise<null>}
 */
export async function getImageFileByReport(id) {
  let data = null;
  console.log("reportid is ",id);
  await statrequest.post(prefix_sau+"/report/file/search", {
    id: id
  }).then(res => {
    console.log(res);
    if (res.flag) {
      data = res.data;
    }
  });
  return data;
}

/**
 * 15、报告管理页面的新建报告单
 * @param id       报告id  暂时无用
 * @param fileIds  多个图片文件id，中间用英文逗号分隔
 * @returns {Promise<null>}
 */
export async function newReport(fileIds,typeValue) {
  let data = null;
  await statrequest.post(prefix_sau+"/report/new", {
//        id: id,
    fileIds: fileIds,
    sampleType:typeValue
  }).then(res => {
    console.log(res);
    data = res;
  });
  return data;
}

/**
 * 16、知识数据库的查询，发送ajax请求，从后台获取图像数据列表，第一页20条记录
 * @param startValue    开始日期，2022-05-08
 * @param endValue      结束日期，2022-05-10
 * @param fileValue     多个文件id，中间用英文逗号分隔
 * @param currentPage   当前页面编号，从1开始
 * @param pageSize      页面的记录数目
 * @returns {Promise<*[]>}
 */
export async function getKnowledgeDataList(startValue, endValue, fileValue, currentPage, pageSize) {
  console.log("getKnowledgeDataList:" + startValue + "," + endValue + "," + fileValue + "," + currentPage + "," + pageSize);
  let data = {flag: true, data: [], total: 0};
  await statrequest.post(prefix_sau+"/knowledge/search", {
    startValue: startValue,
    endValue: endValue,
    fileValue: fileValue,
    currentPage: currentPage,
    pageSize: pageSize
  }).then(res => {
    data = res;
  });
  return data;
}

/**
 * 17、特征新增页面的查看
 * @param id        知识数据id
 * @returns {Promise<null>}
 */
export async function getKnowledgeData(id) {
  let data = null;
  await statrequest.post(prefix_sau+"/knowledge/look", {
    id: id
  }).then(res => {
    console.log(res);
    if (res.flag) {
      data = res.data;
    }
  });
  return data;
}

/**
 * 18、特征新增页面的新增或编辑
 * @param  id 知识数据id
 * @param  arrayFeature  特征对象数组的json
 * @returns {Promise<null>}
 */
export async function editKnowledgeData(id, arrayFeature) {
  let data = null;
  await statrequest.post(prefix_sau+"/knowledge/edit", {
    id: id,
    arrayFeature: arrayFeature
  }).then(res => {
    console.log(res);
    data = res;
  });
  return data;
}

/**
 * 19、特征新增页面的附件上传
 * @param files      上传文件数据列表 new FormData()
 * @returns {Promise<null>}
 */
export async function uploadKnowledgeData(files) {
  let data = null;
  await statrequest({
    url:prefix_sau+"/knowledge/upload",
    method:"post",
    headers:{"Content-Type":"multipart/form-data"},
    data:files}).then(res => {
    console.log(res);
    data = res;
  });
  return data;
}

/**
 * 20、特征新增页面的删除
 * @param id     知识数据id，可以是多个id，中间用英文逗号分隔
 * @returns {Promise<null>}
 */
export async function deleteKnowledgeData(id) {
  let data = null;
  await statrequest.post(prefix_sau+"/knowledge/delete", {
    id: id
  }).then(res => {
    console.log(res);
    data = res;
  });
  return data;
}

/**
 * 21、发送ajax请求，从后台服务器获取识别信息下拉列表数据
 * id表示识别信息id，value表示识别信息名称
 * @returns 数组，数组元素是对象，其中id表示识别信息id，value表示识别信息名称
 */
export async function getResultInfoData() {
  let data = [];
  await statrequest.post(prefix_sau+"/resultinfo/").then(res => {
    if (res.flag) {
      data = res.data;
    }
  });
  return data;
}

/**
 * 22、权限验证接口
 *
 * @param data
 * @returns {*}
 */
export function onLogin(data) {
  return statrequest({
    url: prefix_sau+'/login/',
    method: 'post',
    data: data
  });
}


const StatTokenKey = 'StatToken'

export function getStatToken() {
  return sessionStorage.getItem(StatTokenKey);
}

export function setStatToken(token) {
  sessionStorage.setItem(StatTokenKey, token);
}

export function removeStatToken() {
  sessionStorage.removeItem(StatTokenKey);
}

/**
 * 23、导出统计数据  /stats/download
 * id：报告id，-1表示无效
 * imageFileIds：图像文件id数组
 * dataType：统计数据分类，0表示长度分布；1表示层数分布；2表示片晶个数
 * 数据统计分析和报告管理都导出所选中图像的原始数据，返回excel。
 * 文件格式自己确定，内容是片晶的长度分布、层数分布和个数的原始数据。 responseType: 'blob'
 */
export async function exportData(id,imageFileIds,dataType) {
  let data = null;
  console.log(id,imageFileIds,dataType);
  await statrequest.post(prefix_sau+"/stats/download", {
    id:id,
    imageFileIds:imageFileIds,
    dataType: dataType
  },{responseType: 'blob'}).then(res => {
    data = res;
  }).catch(function(error){
    alert("获取数据失败！"+error.message);
  });
  return data;
}
