<template>
  <a-menu
    style="width: 208px; height: 100vh;padding:0 5px"
    :selected-keys="selectedKeys"
    :openKeys="openKeys"
    mode="inline"
    :multiple="false"
    @openChange="openChange"
  >
    <template v-for="item in sideBarRoutes">
      <a-menu-item
        :key="item.path"
        v-if="item.path === '/home'"
        style="background: #5a65a3; margin-bottom: 0"
      >
        <router-link :to="item.path">{{ item.name }}</router-link>
      </a-menu-item>
      <template v-else>
        <a-sub-menu v-if="item.children && item.children.length > 0">
          <template slot="title">
            <span>{{ item.name }}</span>
          </template>
          <a-menu-item :key="ele.path" v-for="ele in item.children">
            <router-link :to="ele.path"> <i :class="'iconfont ' + ele.meta.icon " ></i>{{ ele.name }}</router-link>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </template>
  </a-menu>
</template>

<script>
import { getUserInfo } from "../utils/auth";
import { constantRoutes } from "../router/index";

export default {
  data() {
    return {
      openKeys: [],
      selectedKeys: [],
      sideBarRoutes: [],
    };
  },
  watch: {
    $route(to, from) {
      this.setSelectKeys();
    },
  },
  created() {
    this.getSideBarRoutes();
    this.setSelectKeys();
    this.setOpenKeys();
    this.$router.push(this.selectedKeys[0]);
  },
  methods: {
    getSideBarRoutes() {
      this.sideBarRoutes = this.filterMenuData(
        constantRoutes,
        JSON.parse(getUserInfo())?.userInfo || []
      );
    },
    // 过滤没有权限的菜单
    filterMenuData(routes, existRoutes) {
      let newRoutes = [];
      routes.map((item) => {
        if (item.hasPermi === false) {
          newRoutes.push(item);
        } else {
          const findRoute = this.findRouteByKey(existRoutes, item.path);
          if (findRoute && !item.hidden) {
            item.meta.title = findRoute?.name || item.meta.title;
            newRoutes.push(item);
          }
        }
      });
      return newRoutes.map((item) => {
        if (item.children && item.children.length > 0) {
          item.children = this.filterMenuData(item.children, existRoutes);
        }
        return item;
      });
    },
    // 查找指定路由
    findRouteByKey(tree, key) {
      let isGet = false;
      let retNode = null;

      function deepSearch(tree, key) {
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].children && tree[i].children.length > 0) {
            deepSearch(tree[i].children, key);
          }

          if (key === tree[i].functionUrl || isGet) {
            isGet || (retNode = tree[i]);
            isGet = true;
            break;
          }
        }
      }
      deepSearch(tree, key);
      return retNode;
    },
    setSelectKeys() {
      let href = window.location.pathname;
      if (!href || href === "/") {
        href = "/home";
      }
      this.selectedKeys = [href];
    },
    setOpenKeys() {
      const currrentSelectedKeys = this.selectedKeys[0];
      const openKeys = [];
      for (let item in this.sideBarRoutes) {
        if (currrentSelectedKeys.indexOf(item.path) > -1) {
          openKeys.push(item.path);
        }
      }
      this.openKeys = openKeys;
    },
    openChange(openKeys) {
      if (openKeys.length > 0) {
        const active = openKeys[openKeys.length - 1];
        this.openKeys = [active];
      } else {
        this.openKeys = [];
      }
    },
  },
};
</script>
<style scoped lang="less">
::v-deep .ant-menu-item{
  padding-left: 24px!important;
}
</style>
