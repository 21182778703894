import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '../layout'

Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
  }
 */

// 公共路由
export const constantRoutes = [{
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [{
            path: '/redirect/:path(.*)',
            component: (resolve) => require(['@/views/redirect'], resolve)
        }]
    },
    {
      name: "错误",
      path: '/error',
      hidden: true,
      component: (resolve) => require(['@/views/error'], resolve),
      meta:{title:'错误！'}
    },
    // 首页
    {
      name: "首页",
      path: "/home",
      component: Layout,
      redirect: 'noredirect',
      hasPermi: true,
      meta: {
          title: '首页'
      },
      children: [{
              name: "首页",
              path: "/home",
              hasPermi: true,
              component: (resolve) => require(['@/components/Workbench'], resolve),
              meta: {
                  title: '首页',
              },
              children: null
          },
      ]
    },
    // 图像识别
    {
      name: "图像识别",
      path: "/database",
      component: Layout,
      redirect: 'noredirect',
      hasPermi: true,
      meta: {
          title: '图像识别'
      },
      children: [{
              name: "图像数据库",
              path: "/database/database",
              hasPermi: true,
              component: (resolve) => require(['@/components/DataHome'], resolve),
              meta: {
                  title: '图像数据库',
                  icon: 'icon-tuxiangimages19'
              },
              children: null
          },{
            name: "图像识别",
            path: "/database/detect",
            hasPermi: true,
            component: (resolve) => require(['@/components/DetectHome'], resolve),
            meta: {
                title: '图像识别',
                icon: 'icon-tuxiangshibie___'
            },
            children: null
          },{
            name: "常规操作",
            path: "/database/opera",
            hasPermi: true,
            component: (resolve) => require(['@/components/OperaHome'], resolve),
            meta: {
                title: '常规操作',
                icon: 'icon-changguicaozuo'
            },
            children: null
          }
      ]
    },
    // 统计与分析
    {
      name: "统计与分析",
      path: "/report",
      component: Layout,
      redirect: 'noredirect',
      hasPermi: true,
      meta: {
          title: '统计与分析'
      },
      children: [{
              name: "数据统计与分析",
              path: "/report/analysis",
              hasPermi: true,
              component: (resolve) => require(['@/components/AnalysisHome'], resolve),
              meta: {
                  title: '数据统计与分析',
                  icon: 'icon-tongjifenxi'
              },
              children: null
          },{
            name: "报告管理",
            path: "/report/report",
            hasPermi: true,
            component: (resolve) => require(['@/components/ReportHome'], resolve),
            meta: {
                title: '报告管理',
                icon: 'icon-baogaoguanli'
            },
            children: null
          },{
            name: "图像数据存储",
            path: "/report/imagedata",
            hasPermi: true,
            component: (resolve) => require(['@/components/ImageStorageHome'], resolve),
            meta: {
                title: '图像数据存储',
                icon: 'icon-fuwuqi'
            },
            children: null
          }
      ]
    },
    // 专家知识库
    {
      name: "专家知识库",
      path: "/knowledge",
      component: Layout,
      redirect: 'noredirect',
      hasPermi: true,
      meta: {
          title: '专家知识库'
      },
      children: [{
              name: "知识数据库",
              path: "/knowledge/knowdata",
              hasPermi: true,
              component: (resolve) => require(['@/components/KnowDataHome'], resolve),
              meta: {
                  title: '知识数据库',
                  icon: 'icon-zhishiku'
              },
              children: null
          },{
            name: "图像检索",
            path: "/knowledge/imagesearch",
            hasPermi: true,
            component: (resolve) => require(['@/components/ImageSearchHome'], resolve),
            meta: {
                title: '图像检索',
                icon: 'icon-nav_tupianjiansuo'
            },
            children: null
          },{
            name: "检索进度",
            path: "/knowledge/imagesearchprogress",
            hasPermi: true,
            component: (resolve) => require(['@/components/ImageSearchProgress'], resolve),
            meta: {
                title: '检索进度',
                icon: 'icon-jindu_shalou'
            },
            children: null
          }
      ]
    },
    // 智能应用自维护
    {
      name: "智能应用自维护",
      path: "/ai",
      component: Layout,
      redirect: 'noredirect',
      hasPermi: true,
      meta: {
          title: '智能应用自维护'
      },
      children: [{
              name: "AI模型管理",
              path: "/ai/aimanage",
              hasPermi: true,
              component: (resolve) => require(['@/components/AiManageHome'], resolve),
              meta: {
                  title: 'AI模型管理',
                  icon: 'icon-jindu_shalou'
              },
              children: null
          },{
            name: "数据集管理",
            path: "/ai/aidataset",
            hasPermi: true,
            component: (resolve) => require(['@/components/AiDataSet'], resolve),
            meta: {
                title: '数据集管理',
                icon: 'icon-shujuji'
            },
            children: null
          },{
            name: "AI模型开发",
            path: "/ai/train",
            hasPermi: true,
            component: (resolve) => require(['@/components/AdjustParamTrain'], resolve),
            meta: {
                title: 'AI模型开发',
                icon: 'icon-AI_zhineng'
            },
            children: null
          }
      ]
    },
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  base: "/",
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({
      y: 0
  }),
  routes: constantRoutes
})