
const TokenKey = 'Token'
const UserInfoKey = 'UserInfo'

export function getToken() {
  return sessionStorage.getItem(TokenKey);
}

export function setToken(token) {
    sessionStorage.setItem(TokenKey, token);
}

export function removeToken() {
    sessionStorage.removeItem(TokenKey);
}

export function getUserInfo() {
  return sessionStorage.getItem(UserInfoKey);
}

export function setUserInfo(userInfo) {
    sessionStorage.setItem(UserInfoKey, userInfo);
}
