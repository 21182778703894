 /**
 * 操作权限处理
 */
 
import store from '@/store'
import { getUserInfo } from '@/utils/auth';


function findRouteByKey(tree, key) {
  let isGet = false;
  let retNode = null;

  function deepSearch(tree, key) {
      for (let i = 0; i < tree.length; i++) {
      if (tree[i].children && tree[i].children.length > 0) {
          deepSearch(tree[i].children, key);
      }
      
      if (key === tree[i].functionUrl || isGet) {
          isGet || (retNode = tree[i]);
          isGet = true;
          break;
      }
      }
  }
  deepSearch(tree, key);
  return retNode;
}

export default {
  inserted(el, binding, vnode) {
    const { value } = binding
    const pathname = window.location.pathname
    const currentRoute = findRouteByKey(JSON.parse(getUserInfo())?.userInfo || [], pathname);

    if (value && value instanceof Array && value.length > 0) {
      let permissionFlag = true;
      value.forEach(per => {
        if(!(currentRoute.buttonList || []).find(ele => ele.buttonNo === per)) {
          permissionFlag = false;
        }
      });

      if (!permissionFlag) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`请设置操作权限标签值`)
    }
  }
}
