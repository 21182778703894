<template >
    <div>
        <div class="contant">
            <img src="../assets/logo.png"/>
            <span>催化剂电镜图像智能识别</span>
        </div>
        <!-- <div style="margin: 20px 0;height: 31px">
            <a-icon type="menu" style="font-size: 20px;color: white"/>
            <span style="font-size:16px;color: white;padding-left: 10px">
                导航<
            </span>
            <a-dropdown :trigger="['click']" style="float: right;color: white" >
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                    >  关闭操作 <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                    <a-menu-item key="0">
                        <a href="http://www.alipay.com/">关闭当前</a>
                    </a-menu-item>
                    <a-menu-item key="1">
                        <a href="http://www.taobao.com/">关闭全部</a>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>

        </div> -->
    </div>



</template>

<script>
    export default {
        data(){
            return{
                lists:[]            //定义一个数组 用于接收路由信息
            }
        },
        created() {
            console.log(this.$route.matched);
            this.lists = this.$route.matched  //获取路由内的全部信息
        },

    watch:{
        $route(to,from)  {
            console.log(to);
            this.lists = to.matched
        }
    },
    }

</script>

<style scoped lang='less'>
.contant{
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    color: #BEB9D7;
    img{
        width: 35px;
        height: 32px;
    }
    span{
        margin-left: 10px;
    }
}
</style>