import axios from 'axios'
import {message} from 'ant-design-vue';
import qs from 'qs';
import { getToken } from './auth'

export const baseUrl = getBaseUrl();

function getBaseUrl() {
  return ['localhost', '127.0.0.1'].indexOf(window.location.hostname) > -1 ? "http://www.hzqyzn.cn/" : window.location.origin;
}
const request = axios.create({
  baseURL: getBaseUrl(), // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
  timeout: 5000000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  config.headers['Authorization'] = getToken();
  config.headers['accept'] = '*';
  config.headers['X-Frame-Options'] = 'SAMEORIGIN';
  // get请求映射params参数
  if (config.method === "get" && config.params) {
    config.paramsSerializer = () => {
      return qs.stringify(config.params, { indices: false })
    };
  }

  return config
}, error => {
  return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
  response => {
    let res = {
      ...response.data,
      success: response.data.code === 200
    };
    if(response.data.code !== 200) {
      message.error(response.data.msg);
      return res;
    }
    // 如果是返回的文件
    if (response.config.responseType === 'blob') {
      return res
    }
    // 兼容服务端返回的字符串数据
    if (typeof res === 'string') {
      res = res ? JSON.parse(res) : res
    }
    return res;
  },
  error => {
    console.log('err' + error); // for debug
    return Promise.reject(error)
  }
)


export default request
