import {
  constantRoutes
} from '@/router'
import store from '@/store';

const permission = {
  state: {
    sidebarRouters: [],
    currentRouters: [],
  },
  mutations: {
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes
    },
    SET_CURRENT_ROUTERS: (state, routes) => {
      state.currentRouters = routes
    },
  },
  actions: {
    // 生成路由
    GenerateRoutes({
      commit
    }) {
      return new Promise(resolve => {
        const permissions = store.getters && store.getters.permissions;
        const accessRoutes = filterMenuData(constantRoutes, permissions);
        commit('SET_CURRENT_ROUTERS', accessRoutes);
        const sideBarRoutes = getSideBarRoutes(accessRoutes);
        commit('SET_SIDEBAR_ROUTERS', sideBarRoutes);
        resolve({
          accessRoutes,
          sideBarRoutes,
        });
      })
    },
  }
}

// 过滤没有权限的菜单
function filterMenuData(routes, existRoutes) {
  let newRoutes = [];
  routes.map(item => {
    if (!item.hasPermi && item.key) {
      newRoutes.push(item);
    } else {
      const findRoute = findRouteByKey(existRoutes, item.key);
      if (findRoute) {
        item.meta.title = findRoute?.name || item.meta.title;
        newRoutes.push(item);
      }
    }
  });
  return newRoutes.map(item => {
    if (item.children && item.children.length > 0) {
      item.children = filterMenuData(item.children, existRoutes)
    }
    return item
  })
}

// 查找指定路由
export function findRouteByKey(tree, key) {
  let isGet = false;
  let retNode = null;

  function deepSearch(tree, key) {
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].subList && tree[i].subList.length > 0) {
        deepSearch(tree[i].subList, key);
      }
      if (key === tree[i].resUrl || isGet) {
        isGet || (retNode = tree[i]);
        isGet = true;
        break;
      }
    }
  }
  deepSearch(tree, key);
  return retNode;
}

// 获取新的地址
function getSideBarRoutes(routes) {
  return routes.map(item => {
    if (item.children && item.children.length > 0) {
      item.children = item.children.map(ele => {
        let newPath = item.path;
        if(ele.path.indexOf('/') === -1) {
          newPath += '/';
        }
        ele.path = newPath + ele.path;
        return ele;
      });
    }
    return item
  })
}

export const loadView = (view) => { // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
