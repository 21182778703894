<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default  {
  name:  'App',
  metaInfo() {
    return {
      title: '',
      titleTemplate: title => {
        return '催化剂电镜图像智能识别'
      }
    }
  }
}
</script>
