import axios from 'axios'
import {message} from 'ant-design-vue';
import qs from 'qs';
import { getStatToken } from './data-manager'

export const baseUrl = '';// 'http://180.76.228.232:8080';
const statrequest= axios.create({
  baseURL: baseUrl,
  timeout: 5000000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
statrequest.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  config.headers['Authorization'] = getStatToken();
  config.headers['accept'] = '*';
  config.headers['X-Frame-Options'] = 'SAMEORIGIN';
  // get请求映射params参数
  if (config.method === "get" && config.params) {
    config.paramsSerializer = () => {
      return qs.stringify(config.params, { indices: false })
    };
  }
  return config
}, error => {
  return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
statrequest.interceptors.response.use(
  response => {
    let res = response.data;
    //后端权限验证未通过，报错
    if(response.data?.code==="401"){
      message.error(response.data?.msg);
      return res;
    }
    // 如果是返回的文件
    if (response.config.responseType === 'blob') {
      return res
    }
    // 兼容服务端返回的字符串数据
    if (typeof res === 'string') {
      res = res ? JSON.parse(res) : res
    }
    return res;
  },
  error => {
    console.log('err' + error); // for debug
    return Promise.reject(error)
  }
)

export default statrequest
