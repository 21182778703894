<template>
  <div id="app">
    <a-layout>
      <a-layout-header>
        <Header/>
      </a-layout-header>
      <a-layout>
        <a-layout-sider style="width: 300px">
          <Aside/>
        </a-layout-sider>
        <a-layout-content style="padding-left: 6px">
          <a-config-provider :locale="zh_CN">
            <!-- <keep-alive> -->
              <router-view :key="$route.fullPath"></router-view>
            <!-- </keep-alive> -->
          </a-config-provider>
        </a-layout-content>
      </a-layout>
      <a-layout-footer style="textAlign: center">
        <a href="https://beian.miit.gov.cn/#/Integrated/index">备案号：浙ICP备2023044111号-1</a>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
  import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";

  import Header from '../components/Header';
  import Aside from "../components/Aside";
  import d from "../components/d";
  export default {
    name:"layout",
    components:{
      Header,
      Aside,
      d
    },
    data(){
      return {
        zh_CN,
      };
    },
    created() {
    },
    methods: {

    }
  }

</script>
