<template>
    <div>
        <canvas
                id="canvas"
                ref="canvas"
                width="1024"
                height="1020"
                @mousedown="canvasDown($event)"
                @mouseup="canvasUp($event)"
                @mousemove="canvasMove($event)"
        ></canvas>

    </div>
</template>

<script>
    import imgurl from '../assets/1.png'
    export default {
        data() {
            return {
                img: new Image(), // 背景图片缓存
                context: {}, // canvas对象
            }
        },
        mounted() {
            this.initDraw()
        },
        methods: {
            initDraw() {
                this.img.src = imgurl
                const canvas = document.querySelector('#canvas')
                this.context = canvas.getContext('2d')

                let w = this.img.width * 1020 / this.img.height
                let h = this.img.height * 1024 / this.img.width
                console.log(w, h)
                canvas.width = w
                canvas.heigth = h
                setTimeout(() => {
                    console.log(this.img.width * 600 / this.img.height)
                    this.context.drawImage(this.img, 0, 0, w, h) //在画布上定位图像
                }, 10)
            },
            canvasDown(e) {
                console.log("按下")
                this.context.font = "20px '微软雅黑'";
                this.context.fillStyle = "red"
                this.context.fillText("画布", e.offsetX, e.offsetY)
            },
            canvasMove(e) {
                console.log("移动")
            },
            canvasUp(e) {
                console.log("抬起")
            },
            resetCanvas() { // 清空画布
                this.context.fillStyle = '#fff'
                this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
                this.context.fillRect(0, 0, this.canvasWidth, this.canvasHeight)
                this.context.fillStyle = '#000'
            },

        }
    }
</script>

<style>
    #canvas {
        border: 1px solid;
    }
</style>
