import router from './router'
import {
  message
} from 'ant-design-vue';
import store from './store'
import {
  getTokenApi
} from "./api/login";
import {
  getToken,
  removeToken,
  setToken,
  setUserInfo,
  getUserInfo
} from './utils/auth';
import { findRouteByKey } from './utils/index';

import {onLogin,getStatToken,setStatToken, removeStatToken} from "@/utils/data-manager";

// 白名单
const whiteList = ['/error']

router.beforeEach((to, from, next) => {
  if (getToken() && getStatToken() && !to.query.token) {//双方后端都验证通过，才可以
    if(to.name) {
      next()
    } else if(to.path === '/' || !findRouteByKey(JSON.parse(getUserInfo())?.userInfo || [], to.path)) {
      next('/home');
    }else{
      next(`/error`)
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      getTokenApi({ token:to.query.token }).then(res => {
        if (res.success) {
          setToken(res.token);
          setUserInfo(JSON.stringify(res));
          //再次验证
          onLogin({token:to.query.token}).then(
            statData=>{
              if(statData.flag){
                setStatToken(statData.token);
                if(to.path === '/') {
                  next('/home');
                }else{
                  next();
                }
              }else{
                next(`/error`)
              }
            });
        } else {
          next(`/error`)
        }
      });
    }
  }
})

function  GetQueryString(name)
{
      var  reg =  new  RegExp( "(^|&)" + name + "=([^&]*)(&|$)" );
      var  r = window.location.search.substr(1).match(reg);
      if (r!= null ) return   unescape(r[2]);  return  null ;
}
