export const findRouteByKey = (tree, key) => {
    let isGet = false;
    let retNode = null;

    function deepSearch(tree, key) {
        for (let i = 0; i < tree.length; i++) {
        if (tree[i].children && tree[i].children.length > 0) {
            deepSearch(tree[i].children, key);
        }
        
        if (key === tree[i].functionUrl || isGet) {
            isGet || (retNode = tree[i]);
            isGet = true;
            break;
        }
        }
    }
    deepSearch(tree, key);
    return retNode;
}