import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'

import router from './router'
import store from './store'
import directive from './directive'
import './permission' // permission control

import 'ant-design-vue/dist/antd.css'
import './style/index.css';
import './style/main.css'
import './style/public.css'
import "@/assets/iconfont/iconfont.css";

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(directive)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app');
