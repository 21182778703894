import request from "../utils/request";
import {
  getToken,
} from '../utils/auth';

export function getTokenApi(data) {
  return request({
    url: '/api/login1',
    method: 'post',
    data: {
      'username':"test",
      'password':"J5?4wmYN\"~hM/RC*",
      'code': data.token //传参Token，如果有就传，没有就不传
    }
  });
}
// 新增快捷方式
export function addUserLabel(data) {
  return request({
    url: '/api/system/user/addUserLabel',
    method: 'post',
    data: data
  });
}

// 获取标签快捷键
export function getUserLabelList(query) {
  return request({
    url: '/api/system/user/userLabellist',
    method: 'get',
    params: query
  });
}
